import React, {Component, createRef, CSSProperties, RefObject, useEffect, useRef, useState} from "react";
import Marquee from "react-fast-marquee";
import SVGPlay from '../../assets/svg/icons/play.svg';
import SVGPause from '../../assets/svg/icons/pause.svg';
import SVGStop from '../../assets/svg/icons/stop.svg';
import SVGPrevious from '../../assets/svg/icons/previous.svg';
import SVGNext from '../../assets/svg/icons/next.svg';

interface EqualizerProps {
    audio: HTMLAudioElement | undefined | null,
    parentRef?: RefObject<HTMLDivElement>
}

const Equalizer = ({audio, parentRef} : EqualizerProps) => {
    const [isPlaying, _setIsPlaying] = useState(false);
    const isPlayingRef = useRef(isPlaying);

    const setIsPlaying = (playing: boolean) => {
        isPlayingRef.current = playing;
        _setIsPlaying(playing)
    }

    const [canvasContext, _setCanvasContext] = useState<CanvasRenderingContext2D | null>(null);
    const canvasContextRef = useRef(canvasContext);

    const setCanvasContext = (canvasContext: CanvasRenderingContext2D | null) => {
        canvasContextRef.current = canvasContext;
        _setCanvasContext(canvasContext)
    }

    const [audioAnalyser, _setAudioAnalyser] = useState<AnalyserNode | null>(null);
    const audioAnalyserRef = useRef(audioAnalyser);

    const setAudioAnalyser = (audioAnalyser: AnalyserNode | null) => {
        audioAnalyserRef.current = audioAnalyser;
        _setAudioAnalyser(audioAnalyser)
    }

    const [audioContext, _setAudioContext] = useState<AudioContext | null>(null);
    const audioContextRef = useRef(audioContext);

    const setAudioContext = (audioContext: AudioContext | null) => {
        audioContextRef.current = audioContext;
        _setAudioContext(audioContext)
    }

    const canvasRef = useRef<HTMLCanvasElement>(null);

    const start = () => {
        if (isPlaying) return;
        if (!canvasRef.current) return;
        if (!audio) return;
        if (!parentRef) return;
        if (!parentRef.current) return;
        if (!audioContextRef.current) return;

        setIsPlaying(true);
        audioContextRef.current.resume();
        setCanvasContext(canvasRef.current
            .getContext('2d'));

        const {width, height} = parentRef.current!.getBoundingClientRect();
        const canvas = canvasRef.current;
        canvas.width = width;
        canvas.height = height - 1;

        window.requestAnimationFrame(render);
    }

    const stop = () => {
        if (!isPlaying) return;
        setIsPlaying(false);
    }

    const render = () => {

        if (!isPlayingRef.current) return;
        if (!canvasContextRef.current) return;
        if (!audioAnalyserRef.current) return;
        if (!canvasRef.current) return;

        const audioAnalyser = audioAnalyserRef.current;
        const canvasContext = canvasContextRef.current;
        const canvas = canvasRef.current;
        const fbc_array = new Uint8Array(audioAnalyser.frequencyBinCount);
        const bar_count = canvas.width / 2;

        audioAnalyser.getByteFrequencyData(fbc_array);

        canvasContext.clearRect(0, 0, canvas.width, canvas.height);
        canvasContext.fillStyle = "#70FF59";

        for (var i = 0; i < bar_count; i++) {
            const bar_pos = i * 4;
            const bar_width = 2;
            const bar_height = -(fbc_array[i] / 6);

            canvasContext.fillRect(bar_pos, canvas.height, bar_width, bar_height);
        }

        window.requestAnimationFrame(render);
    }

    useEffect(() => {
        if (!audio) {
            setIsPlaying(false);
            return;
        }

        const audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();
        const source = audioContext.createMediaElementSource(audio);
        source.connect(analyser);
        analyser.connect(audioContext.destination);
        setAudioAnalyser(analyser);
        setAudioContext(audioContext);

        audio.onplaying = start;
        audio.onpause = stop;
        audio.onabort = stop;
        audio.onclose = stop;
    }, [audio])

    return (
        <canvas ref={canvasRef}></canvas>
    )
}

interface TimAmpState {
    audio: HTMLAudioElement | null;
    progress: number;
}

class TimAmp extends Component<any, TimAmpState> {

    timeCodeRef = createRef<HTMLDivElement>();

    state : TimAmpState = {
        audio: null,
        progress: 0
    }

    componentDidMount() {
        const audio = new Audio('/assets/mp3/test.mp3');
        audio.loop = false;
        audio.autoplay = false;
        audio.ontimeupdate = this.updateProgress.bind(this);

        this.setState({
            audio: audio
        })
    }

    play() {
        if (!this.state.audio) return;
        this.state.audio.play();
    }

    pause() {
        if (!this.state.audio) return;
        this.state.audio.pause();
    }

    stop() {
        if (!this.state.audio) return;
        this.state.audio.pause();
        this.state.audio.currentTime = 0;
    }

    updateProgress() {
        if (!this.state.audio) return;
        const {audio} = this.state;
        const {duration, currentTime} = audio;

        this.setState({
            progress: (currentTime / duration) * 100
        })
    }

    render() {
        return (
            <div className={"c-timamp"}>
                <div className={"c-timamp--row-main"}>
                    <div className={"c-timamp--timecode"} ref={this.timeCodeRef}>
                        <Equalizer audio={this.state && this.state.audio} parentRef={this.timeCodeRef} />
                    </div>

                    <div className={"c-timamp--info-controls"}>
                        <div className={"c-timamp--scrolltext"}>
                            <Marquee gradient={false} speed={60} delay={5}>
                                &nbsp;01 - Some title - Tim de Vries
                            </Marquee>
                        </div>
                        <div className={"c-timamp--audio-info"}>
                            <div className={"c-timamp--kbps"}>
                                <div className={"number"}>
                                    128
                                </div>
                                <div className={"text"}>
                                    kbps
                                </div>
                            </div>
                            <div className={"c-timamp--khz"}>
                                <div className={"number"}>
                                    48
                                </div>
                                <div className={"text"}>
                                    khz
                                </div>
                            </div>

                            <div className={"c-timamp--mono-stereo"}>
                                <div className={"item"}>mono</div>
                                <div className={"item active"}>stereo</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"c-timamp--row-progress"}>
                    <div className={"c-timamp--progress"}>
                        <div className={"c-timamp--current-progress"} style={{
                            width: this.state.progress + '%'
                        } as CSSProperties} />
                    </div>
                </div>

                <div className={"c-timamp--row-toolbar"}>
                    <div className={"control"}>
                        <SVGPrevious />
                    </div>
                    <div className={"control"} onClick={this.play.bind(this)}>
                        <SVGPlay />
                    </div>
                    <div className={"control"} onClick={this.pause.bind(this)}>
                        <SVGPause />
                    </div>
                    <div className={"control"} onClick={this.stop.bind(this)}>
                        <SVGStop />
                    </div>
                    <div className={"control"}>
                        <SVGNext />
                    </div>
                </div>
            </div>
        )
    }
}

export default TimAmp;