export const Tim = `
:::::::::::*FV$M$$$MMMMMM$M$F:::::::::::
:::::::::*$M$IFIIFFFIVVV$$$$$$*:::::::::
::::::::*$MM$F**:::::::::**F$$MV::::::::
:::::::*$MM$I**::::......:::F$MNM*::::::
::::::*$MMM$F*:::.........::*$NNN$*:::::
:::::*$MMMM$**:::.........::*MNNNM**::::
:::::VMMMMMF******:...:**::::MNNNNV*::::
:::::$MMIVM*::***:*::::***:::VNVMMVI::::
:::::IMMF*I*:....:::::::....:I**MNI**:::
:::::FMM$****::.:**:.::....::::VNN$**:::
:::::F$MM$V***:::*F**F::.::::*VMMM$I*:::
:::::*$V$MNI***FIVI**FF**:::*MNN$F$V*:::
::::::*:VMN$F*****::::::::::VNNNMVV$*:::
:::::*.:$NNMIVIF*::**::**:**NNNNM$F*::::
:::::IV$MNNNMMMM$F*::*FV$F**NNNMNMV*::::
::::**F$MN$IMVVV$$M$$$VF*:::INNNNN$I*:::
*I$MMMMMNN$:******:::::::::.VMNMNMF:::::
V$$$$$M$MMM:.******::::::*.:$MM$MM$$$I**
V$$$$V$MMMM*::******:***:..:$$MV$$$$$$$$
`;