import React, {MouseEventHandler, PropsWithChildren} from "react";

export interface ContextMenuItemProps extends PropsWithChildren {
    text: string;
    href?: string;
    onClick?: MouseEventHandler;
}

const ContextMenuItem = ({children, text, href, onClick} : ContextMenuItemProps) => {
    const hrefDefined = typeof href !== 'undefined';
    const TagElement = hrefDefined ? 'a' : 'div' as keyof JSX.IntrinsicElements;

    return (
        <li>
            <TagElement className={"c-context-menu--item"}
                        href={href}
                        onClick={onClick}>
                <div className={"c-context-menu--icon"}>{children}</div>
                <div className={"c-context-menu--text"}>{text}</div>
            </TagElement>
        </li>
    )
}

export default ContextMenuItem;