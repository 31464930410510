import React, {Component, createRef, PropsWithChildren} from "react";

export interface ContextMenuProps extends PropsWithChildren {
}

interface ContextMenuState {
}

class ContextMenu extends Component<ContextMenuProps, ContextMenuState> {

    contextMenuRef = createRef<HTMLDivElement>();

    hide() {
        const contextMenu = this.contextMenuRef.current;
        if (!contextMenu) return;

        contextMenu.style.display = 'none';
    }


    show(x: number, y: number) {
        const contextMenu = this.contextMenuRef.current;
        if (!contextMenu) return;

        const offsetMargin = 5; //px
        const {width, height} = contextMenu.getBoundingClientRect();
        let newX = x + offsetMargin;
        let newY = y + offsetMargin;

        if (newX + width > window.innerWidth) {
            newX = x - width - offsetMargin;
        }

        if (newY + height > window.innerHeight) {
            newY = y - height - offsetMargin;
        }

        contextMenu.style.display = 'block';
        contextMenu.style.top = newY + 'px';
        contextMenu.style.left = newX + 'px';
    }

    preventClick(e: any) {
        e.preventDefault();
    }

    render() {

        return (
            <div className={"c-context-menu"} ref={this.contextMenuRef} onClick={this.hide.bind(this)} onContextMenu={this.preventClick}>
                <ul className={"c-context-menu--table"}>
                    {this.props.children}

                    <li>
                        <a href={"#"} className={"c-context-menu--item"}>
                            <div className={"c-context-menu--icon"}><img src={"/assets/svg/logo.svg"} alt={"icon"} /></div>
                            <div className={"c-context-menu--text"}>The Getaway Case</div>
                        </a>
                    </li>
                    <li>
                        <a href={"https://studiotimdevries.com/"} target={"_blank"}
                           className={"c-context-menu--item"}>
                            <div className={"c-context-menu--icon"}>&copy;</div>
                            <div className={"c-context-menu--text"}>Studio Tim de Vries</div>
                         </a>
                    </li>
                </ul>
            </div>
        )
    }
}

export default ContextMenu;