import React, {Component, CSSProperties} from "react";
import SVGFloppy from '../../assets/svg/icons/floppy.svg';
import SVGFolder from '../../assets/svg/icons/folder.svg';

export enum ShortcutIconType {
    FOLDER,
    FLOPPY
}

interface ShortcutProps {
    title: string,
    iconType: ShortcutIconType,
    action(): void,
    position?: {
        left?: string | number,
        top?: string | number,
        bottom?: string | number,
        right?: string | number
    }
}

class Shortcut extends Component<ShortcutProps, any> {

    getIcon(type: ShortcutIconType) {
        switch (type) {
            case ShortcutIconType.FLOPPY:
                return (<SVGFloppy />)

            case ShortcutIconType.FOLDER:
            default:
                return (<SVGFolder />)
        }
    }

    render() {
        const {
            iconType, title,
            action, position
        } = this.props;

        const icon = this.getIcon(iconType);
        const style = {} as CSSProperties;

        if (position) {
            if (position.top) {
                style['top'] = position.top;
            }

            if (position.right) {
                style['right'] = position.right;
            }

            if (position.bottom) {
                style['bottom'] = position.bottom;
            }

            if (position.left) {
                style['left'] = position.left;
            }
        }

        return (
            <div className={"c-shortcut"} onClick={action} style={style}>
                <div className={"c-shortcut--icon"}>{icon}</div>
                <div className={"c-shortcut--title"}>{title}</div>
            </div>
        )
    }
}

export default Shortcut;