import React, {Component, RefObject} from "react";
import Typewriter, {TypewriterClass} from "typewriter-effect";
import Window from "./Window";
import {Tim} from "./Art/Tim";


export interface TerminalProps {
    parentRef: RefObject<Window>;
    zIndex: string;
}

interface TerminalState {
    typewriter?: TypewriterClass,
    writtenText: string
}

class Terminal extends Component<TerminalProps, TerminalState> {

    state : TerminalState = {
        writtenText: ''
    }

    componentDidMount() {
        window.addEventListener('keyup', this.registerKey.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('keyup', this.registerKey);
    }

    registerKey(e: KeyboardEvent) {
        const parentWindow = this.props.parentRef.current;
        const typewriter = this.state.typewriter;

        if (!parentWindow || !typewriter) return;

        if (this.props.zIndex.toString() === parentWindow.getZIndex()) {
            const {writtenText} = this.state;

            if (writtenText.toLowerCase() === 'tim' && e.key === 'Enter') {
                typewriter
                    .deleteChars(writtenText.length + 3)
                    .pauseFor(100);

                this.setState({
                    writtenText: ''
                })

                const ascii = Tim.split("\n");

                for (let line of ascii) {
                    const words = line.split(' ');
                    for (let word of words) {
                        typewriter
                            .pasteString(word, null)
                            .pasteString('<span class="space"> </span>', null);
                    }

                    typewriter
                        .pasteString("<br />", null);

                }

                typewriter.typeString('A: ')
                    .start();
            }
            else if (e.key === 'Backspace') {
                if (writtenText.length === 0) {
                    return;
                }

                this.setState({
                    writtenText: writtenText.slice(0, writtenText.length - 1)
                })

                typewriter
                    .deleteChars(1)
                    .start()
            } else if (/^[a-zA-Z-\s]$/.test(e.key)) {
                this.setState({
                    writtenText: writtenText + e.key
                })

                typewriter
                    .typeString(e.key)
                    .start()
            }

        }
    }

    render() {
        const text = `// Case Number: 001.289
Case content in this case:

Treachery_Bill_v1
Short description of this item and what it is, and maybe why it is int he case.

___________

Butterfly_Knife_v1
Short description of this item and what it is, and maybe why it is int he case.

___________
`;

        return (
            <div className={"c-terminal"}>
                <div className={"c-terminal--content"}>
                    <Typewriter
                        options={{
                            deleteSpeed: 0,
                            delay: 10,
                            autoStart: true
                        }}
                        onInit={(typewriter) => {
                            typewriter.pauseFor(1000)
                                .typeString(`STUDIO TIM DE VRIES`)
                                .pasteString("<br />", null)
                                .typeString('THE GETAWAY CASE NFT 2022 V1')
                                .pasteString("<br />", null)
                                .pasteString("<br />", null)
                                .pauseFor(1500);

                            const lines = text.split("\n");

                            for (const line of lines) {
                                typewriter.pasteString(line, null)
                                    .typeString("<br />")
                                    .pauseFor(50)
                            }

                            typewriter.typeString('A: ')
                            typewriter.start();

                            this.setState({
                                typewriter: typewriter
                            })
                        }}
                    />
                </div>
            </div>
        )
    }
}

export default Terminal;