import React, {RefObject, useEffect, useRef, useState} from "react";
import Layout from "../components/Layout";
import Window from "../components/Desktop/Window";
import Terminal from "../components/Desktop/Terminal";
import Shortcut, {ShortcutIconType} from "../components/Desktop/Shortcut";
import ContextMenu from "../components/Desktop/ContextMenu";
import ContextMenuItem from "../components/Desktop/ContextMenu/Item";
import SVGFloppy from '../assets/svg/icons/floppy.svg';
import SVGPlay from '../assets/svg/icons/play.svg';
import TimAmp from "../components/Desktop/TimAmp";
import Folder from "../components/Desktop/Folder";

interface TaskbarItem {
    ref: RefObject<Window>
}

const DesktopPage = () => {

    const [taskbarItems, setTaskbarItems] = useState<TaskbarItem[]>([]);
    const [zIndex, setZIndex] = useState(1);

    const desktopRef = useRef<HTMLDivElement>(null);
    const billRef = useRef<Window>(null);
    const nftRef = useRef<Window>(null);
    const terminalRef = useRef<Window>(null);
    const behindTheScenesRef = useRef<Window>(null);
    const contextMenuRef = useRef<ContextMenu>(null);
    const timAmpWindowRef = useRef<Window>(null);
    const timpAmpRef = useRef<TimAmp>(null);

    function getZIndex() {
        setZIndex(zIndex + 1);
        return (zIndex + 1).toString(10);
    }

    const addToTaskbar = (ref: RefObject<Window>) => {
        const newTaskbarItem : TaskbarItem = {
            ref
        }

        setTaskbarItems([...taskbarItems, newTaskbarItem])
    };

    const showAndRemoveFromTaskbar = (ref: RefObject<Window>) => {
        if (!ref.current) return;
        ref.current.show();

        const newItems = [...taskbarItems];
        const index = taskbarItems.findIndex((item) => item.ref === ref)

        if (index !== -1) {
            newItems.splice(index, 1)
            setTaskbarItems(newItems);
        }
    }

    const showContextMenu = (e: MouseEvent) => {
        e.preventDefault();

        if (contextMenuRef && contextMenuRef.current) {
            contextMenuRef.current.show(e.clientX, e.clientY);
        }

        return false;
    }

    const hideContextMenu = () => {
        if (contextMenuRef && contextMenuRef.current) {
            contextMenuRef.current.hide();
        }
    }

    useEffect(() => {
        if (!desktopRef.current) return;
        desktopRef.current.addEventListener('contextmenu', showContextMenu);
        desktopRef.current.addEventListener('click', hideContextMenu);
        desktopRef.current.addEventListener('touchstart', hideContextMenu);

        return () => {
            if (!desktopRef.current) return;
            desktopRef.current.removeEventListener('contextmenu', showContextMenu);
            desktopRef.current.removeEventListener('click', hideContextMenu);
            desktopRef.current.removeEventListener('touchstart', hideContextMenu);
        }
    }, [])

    return (
        <Layout>
            <div className={"c-desktop"} ref={desktopRef}>
                <Window width={'400px'}
                        height={'400px'}
                        title={"Treachery_Bill_v1"}
                        bottomTitle={'In the register'}
                        action={'Print'}
                        startPosition={{ x: '10%', y: '10%' }}
                        onMinimize={() => addToTaskbar(billRef)}
                        zIndexProvider={getZIndex}
                        ref={billRef}>
                    <img src={"/assets/img/img_1.png"} />
                </Window>

                <Window width={'450px'}
                        height={'400px'}
                        title={"The Getaway Case NFT"}
                        bottomTitle={'001:289.mp4'}
                        action={'Download'}
                        startPosition={{ x: '25%', y: '25%' }}
                        onMinimize={() => addToTaskbar(nftRef)}
                        zIndexProvider={getZIndex}
                        ref={nftRef}>
                    <img src={"/assets/img/img_2.png"} />
                </Window>

                <Window width={'350px'}
                        height={'400px'}
                        title={"Terminal"}
                        bottomTitle={''}
                        action={'Print'}
                        startPosition={{ x: '5%', y: '5%' }}
                        canMaximize={true}
                        onMinimize={() => addToTaskbar(terminalRef)}
                        zIndexProvider={getZIndex}
                        ref={terminalRef}>
                    <Terminal parentRef={terminalRef} zIndex={zIndex.toString(10)} />
                </Window>

                <Window width={'350px'}
                        height={'400px'}
                        title={"Behind the scenes"}
                        bottomTitle={''}
                        action={'Print'}
                        canMaximize={true}
                        startPosition={{ x: '20%', y: '20%' }}
                        onMinimize={() => addToTaskbar(behindTheScenesRef)}
                        zIndexProvider={getZIndex}
                        ref={behindTheScenesRef}>
                    <Folder>
                        <Shortcut
                            title={"001:289.mp4"}
                            action={() => showAndRemoveFromTaskbar(nftRef)}
                            iconType={ShortcutIconType.FLOPPY} />
                        <Shortcut
                            title={"001:289.mp4"}
                            action={() => showAndRemoveFromTaskbar(nftRef)}
                            iconType={ShortcutIconType.FOLDER} />
                        <Shortcut
                            title={"001:289.mp4"}
                            action={() => showAndRemoveFromTaskbar(nftRef)}
                            iconType={ShortcutIconType.FLOPPY} />
                    </Folder>
                </Window>

                <Window width={'400px'}
                        height={'220px'}
                        title={'TimAmp'}
                        bottomTitle={''}
                        action={''}
                        startPosition={{ x: '60%', y: '10%' }}
                        zIndexProvider={getZIndex}
                        onMinimize={() => addToTaskbar(timAmpWindowRef)}
                        onClose={() => {
                            if (!timpAmpRef.current) return;
                            timpAmpRef.current.stop();
                        }}
                        ref={timAmpWindowRef}
                        >
                    <TimAmp ref={timpAmpRef} />
                </Window>
            </div>
            <div className={"c-desktop--taskbar"}>
                <div className={"c-desktop--taskbar-items"}>
                {taskbarItems.map((taskbarItem, index) => (
                    <div className={"c-desktop--taskbar-item o-bg--primary"}
                         key={index}
                        onClick={(e) => {
                            if (!taskbarItem.ref || !taskbarItem.ref.current) return

                            taskbarItem.ref.current.restoreMinimize({
                                from: {x: e.clientX, y: e.clientY}
                            })

                            const newItems = [...taskbarItems];
                            newItems.splice(index , 1)
                            setTaskbarItems(newItems);
                        }}>
                        {taskbarItem.ref.current && taskbarItem.ref.current.props.title}
                    </div>
                ))}
                </div>
            </div>

            <div className={"c-desktop--shortcuts"}>
                <Shortcut
                    title={"001:289.mp4"}
                    action={() => showAndRemoveFromTaskbar(nftRef)}
                    iconType={ShortcutIconType.FLOPPY} />

                <Shortcut
                    title={"Case content"}
                    action={() => showAndRemoveFromTaskbar(billRef)}
                    position={{
                        left: 0,
                        top: '150px'
                    }}
                    iconType={ShortcutIconType.FOLDER} />

                <Shortcut
                    title={"Behind the scenes"}
                    action={() => showAndRemoveFromTaskbar(behindTheScenesRef)}
                    position={{
                        left: '200px',
                        top: '150px'
                    }}
                    iconType={ShortcutIconType.FOLDER} />
            </div>

            <div className={"c-desktop--controls"}>
                <button className={"o-button c-desktop--logout"}>
                    Logout
                </button>
            </div>

            <ContextMenu ref={contextMenuRef}>
                <ContextMenuItem text={"Terminal"} onClick={() => showAndRemoveFromTaskbar(terminalRef)}>
                    <SVGFloppy className={"c-context-menu--terminal"} />
                </ContextMenuItem>
                <ContextMenuItem text={"Player"} onClick={() => showAndRemoveFromTaskbar(timAmpWindowRef)}>
                    <SVGPlay className={"c-context-menu--terminal"} />
                </ContextMenuItem>
            </ContextMenu>
        </Layout>
    )
}

export default DesktopPage;