import React, {ReactElement} from 'react';
import Shortcut from "./Shortcut";


interface FolderProps {
    children: ReactElement<Shortcut>[] | ReactElement<Shortcut>
}

const Folder = ({children} : FolderProps) => {
    return (
        <div className={"c-folder"}>
            <div className={"c-folder--item-grid"}>
                {children}
            </div>
        </div>
    )
};

export default Folder;