import React, {
    Component, createRef,
    CSSProperties,
    MouseEventHandler,
    PropsWithChildren
} from "react";
import SVGMinus from "../../assets/svg/control-minus.svg";
import SVGMax from "../../assets/svg/control-max.svg";
import SVGClose from "../../assets/svg/control-close.svg";
import gsap from "gsap";

interface ControlButtonProps extends PropsWithChildren {
    className: string;
    onClick?: MouseEventHandler<HTMLDivElement | null>;
}

const ControlButton = ({children, className, onClick} : ControlButtonProps) => {
    return (
        <div className={className} onClick={onClick}>
            {children}
        </div>
    )
}

export interface WindowProps extends  PropsWithChildren {
    title: string;
    bottomTitle: string;
    action: string;
    width: string;
    height: string;
    hidden?: boolean;
    canMaximize?: boolean;
    startPosition: {
        x: string | number,
        y: string | number
    }
    onMinimize(): void;
    zIndexProvider(): string;
    onClose?: () => void;
}

class WindowComponent extends Component<WindowProps, any> {

    windowMinimizeCloneRef;
    windowRef;
    draggableElementRef;
    state = {
        originalPosition: {
            x: 0,
            y: 0
        },
        isMaximized: false,
        width: this.props.width,
        height: this.props.height
    };

    constructor(props: WindowProps) {
        super(props);

        this.windowMinimizeCloneRef = createRef<HTMLDivElement>();
        this.windowRef = createRef<HTMLDivElement>();
        this.draggableElementRef = createRef<HTMLDivElement>();
    }

    show = () => {
        const windowRef = this.windowRef.current;

        if (!windowRef) return;
        windowRef.style.display = 'flex';
        windowRef.style.zIndex = this.props.zIndexProvider();
    }

    close = () => {
        if (!this.windowRef.current) return;
        this.windowRef.current.style.display = 'none';

        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    maximize = () => {
        const windowRef = this.windowRef.current;
        if (!windowRef) return;

        if (this.state.isMaximized) {
            const {x, y} = this.state.originalPosition;

            this.setState({
                isMaximized: false,
                width: this.props.width,
                height: this.props.height
            });

            windowRef.style.left = x + 'px';
            windowRef.style.top = y + 'px';

        } else {
            const {x, y} = windowRef.getBoundingClientRect();

            this.setState({
                originalPosition: {x, y},
                isMaximized: true,
                width: window.innerWidth + 'px',
                height: window.innerHeight + 'px'
            });

            windowRef.style.left = '0';
            windowRef.style.top = '0';
        }
    }

    minimize = () => {
        if (!this.windowRef.current) return;
        if (!this.windowMinimizeCloneRef.current) return;

        const minimizeElement = this.windowMinimizeCloneRef.current;
        const parentElement = this.windowRef.current;
        const { x, y } = parentElement.getBoundingClientRect();


        minimizeElement.style.top = y + 'px';
        minimizeElement.style.left = x + 'px';
        minimizeElement.style.display = 'flex';

        parentElement.style.display = 'none';

        // Store the original position to return to
        this.setState({
            originalPosition: {x, y}
        });

        this.getMinimizeAnimation(this.windowMinimizeCloneRef.current)
            .eventCallback("onComplete", this.props.onMinimize)
            .play();
    }

    restoreMinimize = ({ from } : { from: { x: number, y: number }}) => {
        if (!this.windowRef.current) return;
        if (!this.windowMinimizeCloneRef.current) return;

        const parentElement = this.windowRef.current;
        const minimizeElement = this.windowMinimizeCloneRef.current;
        minimizeElement.style.top = from.y + 'px';
        minimizeElement.style.left = from.x + 'px';
        minimizeElement.style.display = 'flex';

        const stepYSize = (window.innerHeight - this.state.originalPosition.y) / 3;
        const stepXSize = this.state.originalPosition.x / 3;

        gsap.timeline()
            .to(minimizeElement, {
                scale: 0,
                y: 0,
                x: 0,
                duration: 0
            }, 0)
            .to(minimizeElement, {
                scale: 0.4,
                top: this.state.originalPosition.y + (stepYSize*2),
                left: stepXSize,
                duration: 0
            }, 0.1)
            .to(minimizeElement, {
                scale: 0.8,
                top: this.state.originalPosition.y + stepYSize,
                left: stepXSize * 2,
                duration: 0
            }, 0.2)
            .to(minimizeElement, {
                scale: 1,
                top: this.state.originalPosition.y,
                left: stepXSize * 3,
                duration: 0,
                onComplete: () => {
                    minimizeElement.style.display = 'none';
                    parentElement.style.display = 'flex';
                }
            }, 0.3)
    }

    getMinimizeAnimation = (target: HTMLDivElement) => {
        const {y, x} = target.getBoundingClientRect();
        const stepYSize = (window.innerHeight - y) / 3
        const stepXSize = x / 3;

        return gsap.timeline()
            .to(target, {
                scale: 1,
                y: 0,
                x: 0,
                duration: 0
            }, 0)
            .to(target, {
                scale: 0.8,
                y: stepYSize,
                x: -stepXSize,
                duration: 0
            }, 0.1)
            .to(target, {
                scale: 0.4,
                y: stepYSize * 2,
                x: -stepXSize * 2,
                duration: 0,
            }, 0.2)
            .to(target, {
                scale: 0,
                y: stepYSize * 3,
                x: -stepXSize * 3,
                duration: 0
            }, 0.3)
    }

    getZIndex() {
        if (!this.windowRef.current) return;

        const element = this.windowRef.current;
        return element.style.zIndex;
    }

    componentDidMount() {
        let cursorPos = {
            x: 0,
            y: 0
        };

        if (typeof this.props.hidden === 'undefined' || (typeof this.props.hidden !== 'undefined' && this.props.hidden)) {
            this.close()
        }

        if (this.draggableElementRef.current) {
            this.draggableElementRef.current.addEventListener('mousedown', dragMouseDown);
        }

        const windowRef = this.windowRef.current;

        if (windowRef) {
            windowRef.style.zIndex = this.props.zIndexProvider();
            gsap.to(this.windowRef.current, {
                left: this.props.startPosition.x,
                top: this.props.startPosition.y,
                duration: 0
            })
        }

        function updateCursorPos(e: MouseEvent) {
            cursorPos = {
                x: e.clientX,
                y: e.clientY
            }
        }

        function dragMouseDown(e: MouseEvent) {
            e.preventDefault();
            updateCursorPos(e);

            document.addEventListener('mouseup', closeDragElement);
            document.addEventListener('mousemove', elementDrag);
        }

        const elementDrag = (e: MouseEvent) => {
            e.preventDefault();

            if (!this.windowRef.current) return;
            if (this.state.isMaximized) return;

            const element = this.windowRef.current;
            const { width, height } = element.getBoundingClientRect();

            let newX = Math.max(0, element.offsetLeft - (cursorPos.x - e.clientX));
            let newY = Math.max(0, element.offsetTop - (cursorPos.y - e.clientY));

            if (newX + width > window.innerWidth) {
                newX = window.innerWidth - width;
            }

            if (newY + height > window.innerHeight) {
                newY = window.innerHeight - height;
            }

            element.style.top = newY + "px";
            element.style.left = newX + "px";
            element.style.zIndex = this.props.zIndexProvider();

            updateCursorPos(e);
        }

        function closeDragElement() {
            document.removeEventListener('mouseup', closeDragElement);
            document.removeEventListener('mousemove', elementDrag);
        }

        return () => {
            closeDragElement();

            if (this.draggableElementRef.current) {
                this.draggableElementRef.current.removeEventListener('mousedown', dragMouseDown);
            }
        }
    }

    render() {
        return (
            <>
                <div className={"c-window"} ref={this.windowRef}
                     style={{'--width': this.state.width, '--height': this.state.height} as CSSProperties}>
                    <div className={"c-window--top-bar"} ref={this.draggableElementRef}>
                        <div className={"title"}>{this.props.title}</div>
                        <div className={"controls"}>
                            <ControlButton className={"control minimize"} onClick={this.minimize}>
                                <SVGMinus/>
                            </ControlButton>

                            {this.props.canMaximize && (
                                <ControlButton className={"control maximize"} onClick={this.maximize}>
                                    <SVGMax/>
                                </ControlButton>
                            )}
                            <ControlButton className={"control close"} onClick={this.close}>
                                <SVGClose/>
                            </ControlButton>
                        </div>
                    </div>

                    <div className={"c-window--content"} onClick={this.show}>
                        {this.props.children}
                    </div>
                    <div className={"c-window--bottom-bar"}>
                        <div className={"title"}>{this.props.bottomTitle}</div>
                        <div className={"controls"}>
                            {this.props.action}
                        </div>
                    </div>
                </div>
                <div className={"c-window c-window--clone"}
                     ref={this.windowMinimizeCloneRef}
                     style={{'--width': this.state.width, '--height': this.state.height} as CSSProperties}>
                    <div className={"c-window--top-bar"}>
                        <div className={"title"}>{this.props.title}</div>
                    </div>
                </div>
            </>
        )
    }
}

export default WindowComponent;